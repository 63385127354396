<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <div
      v-if="sidebar"
      class="form-title"
    >
      <span class="info-title">{{ info_title }}</span>
    </div>
    <div :class="[ confirmed ? 'display' : '', 'request-confirmation' ]">
      <p>{{ $t('messages.sent_to_email',{'email': email, 'brand_name': brand_name }) }}</p>

      <p>
        {{ $t('messages.questions') }} 
        <a
          role="button"
          v-html="phoneLink()" 
        />
      </p>
      <a
        class="btn close-btn"
        role="button"
        @click="closeConfirmation()"
      >{{ $t('messages.close') }}</a>
    </div>

    <div :class="[ confirmed ? '' : 'display', 'customer-request-form' ]">
      <div
        v-if="sidebar"
        class="form-instructions"
      >
        <div
          class="custom-travel"
          v-html="info"
        />
      </div>
      <VForm
        id="new_info_request"
        ref="form"
        class="simple_form info_request"
        @submit="submitForm"
      >
        <input
          id="info_request_referring_page"
          value="/contact-us"
          type="hidden"
          name="referring_page"
        >
        <div class="column col">
          <input
            id="gclid_field"
            v-model="gcl_id"
            name="gcl_id"
            type="hidden"
          >
          <div
            class="form-group"
          >
            <Field
              id="info_request_first_name"
              v-model="first_name"
              class="form-control"
              :placeholder="$t('form.first_name')"
              type="text"
              name="first_name"
              rules="required"
            />
            <ErrorMessage
              as="label"
              class="error"
              name="first_name"
            />
          </div>
          <div
            class="form-group"
          >
            <Field
              id="info_request_last_name"
              v-model="last_name"
              class="form-control"
              :placeholder="$t('form.last_name')"
              type="text"
              name="last_name"
              rules="required"
            />
            <ErrorMessage
              as="label"
              class="error"
              name="last_name"
            />
          </div>
          <div
            class="form-group"
          >
            <Field
              id="info_request_email"
              v-model="email"
              class="form-control"
              :placeholder="$t('form.email')"
              type="email"
              name="email"
              :rules="validateEmail"
            />
            <ErrorMessage
              as="label"
              class="error"
              name="email"
            />
          </div>
          <div id="hint" />
          <div
            class="form-group"
          >
            <Field
              id="info_request_phone"
              v-model="phone"
              class="form-control"
              :placeholder="$t('form.phone')"
              type="text"
              name="phone"
              :rules="validatePhone"
            />
            <ErrorMessage
              as="label"
              class="error"
              name="phone"
            />
          </div>
          <div
            v-if="roomcashWindow"
            class="form-group string optional event_or_company_name"
          >
            <Field
              id="info_request_event_or_company_name"
              v-model="event_or_company_name"
              class="form-control string optional"
              :placeholder="$t('form.event_or_company_name')"
              type="text"
              name="event_or_company_name"
            />
            <ErrorMessage
              as="label"
              class="error"
              name="event_or_company_name"
            />
          </div>
        </div>
        <div class="column col">
          <div
            v-if="resbeatWindow"
            class="display company corporate_fields"
          >
            <div class="form-group string optional info_request_company_name">
              <Field
                id="info_request_company_name"
                v-model="company_name"
                class="form-control string optional"
                placeholder="Company"
                type="text"
                name="company_name"
                autocomplete="off"
              />
              <ErrorMessage
                as="label"
                class="error"
                name="company_name"
              />
            </div>
          </div>

          <div
            v-if="eventOptions.length > 1 || categoryOptions.length > 1"
            class="form-group"
          >
            <i
              v-if="resbeatWindow && eventOptions.length > 1 || categoryOptions.length > 1 && !sportsnetWindow"
              class="fa fa-angle-down"
              aria-hidden="true"
            />
            <Field
              id="info_request_event_id"
              v-model="event_id"
              as="select"
              class="form-control select info_request_event_id"
              name="event_id"
              :rules="validateEvent"
            >
              <template v-if="categoryOptions.length > 1">
                <option value="">
                  Event
                </option>
                <optgroup
                  v-for="category in categoryOptions"
                  :key="category.name"
                  :label="category.name"
                >
                  <option
                    v-for="(option, index) in category.events"
                    :key="index"
                    :value="option.id"
                  >
                    {{ option.label || option.display_name }}
                  </option>
                </optgroup>
              </template>
              <template v-else>
                <option
                  v-for="(option, index) in eventOptions"
                  :key="index"
                  :value="option.id"
                >
                  {{ option.label || option.display_name }}
                </option>
              </template>
            </Field>
            <ErrorMessage
              as="label"
              class="error"
              name="event_id"
            />
          </div>

          <div
            v-if="nba_info_request.isNbaForm"
            class="form-group"
          >
            <Field
              id="info_request_nba_favorite_team"
              v-model="favorite_team"
              as="select"
              class="form-control select info_request_nba_favorite_team"
              name="favorite_team"
            >
              <option value="">
                Favorite Team
              </option>
              <option
                v-for="(team, index) in nba_favorite_teams"
                :key="index"
                :value="team"
              >
                {{ team }}
              </option>
            </Field>
          </div>
          <div class="form-group">
            <Field
              v-if="resbeatWindow"
              id="info_request_how_many"
              v-model="how_many"
              placeholder="Average Hotel Reservations Per Year"
              type="number"
              name="how_many"
              class="form-control"
              min="0"
              max="100000000"
            />

            <Field
              v-else-if="!quintroomsWindow && !roomcashWindow"
              id="info_request_how_many"
              v-model="how_many"
              as="select"
              class="form-control"
              name="how_many"
              @change="updateFontColor"
            >
              <option
                v-for="option in howManyOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </Field>
          </div>

          <div
            v-if="!quintroomsWindow && !roomcashWindow"
            class="form-group boolean optional info_request_corporate_fields"
          >
            <div class="checkbox">
              <label>
                <input
                  id="info_request_corporate_fields"
                  v-model="corporate_request"
                  :disabled="reseller_checkbox"
                  class="boolean optional"
                  type="checkbox"
                  name="corporate_fields"
                >
                {{ $t('form.corporate') }}
                <span class="custom-checkbox" />
              </label>
            </div>
          </div>


          <div :class="[corporate_request ? 'display' : '', 'corporate_fields']">
            <div class="form-group string optional info_request_company_name">
              <Field
                id="info_request_company_name"
                v-model="company_name"
                :disabled="corporate_request ? false : true"
                :style="{ display: corporate_request ? 'inherit' : 'none' }"
                class="form-control string optional"
                :placeholder="$t('form.company_name')"
                type="text"
                name="company_name"
                autocomplete="off"
                :rules="validateCompanyName"
              />
              <ErrorMessage
                as="label"
                class="error"
                name="company_name"
              >
                {{ $t('messages.required') }}
              </ErrorMessage>
            </div>
          </div>

          <div
            v-if="!resbeatWindow && !quintroomsWindow && !roomcashWindow"
            class="form-group boolean optional info_request_reseller_checkbox"
          >
            <div class="checkbox">
              <label>
                <input
                  id="info_request_advisor_or_resller"
                  v-model="reseller_checkbox"
                  :disabled="corporate_request"
                  class="boolean optional"
                  type="checkbox"
                  name="reseller_checkbox"
                >
                {{ $t('form.reseller_checkbox') }}
                <span class="custom-checkbox" />
              </label>
            </div>
          </div>
        </div>
        <div class="column col">
          <div class="form-group">
            <Field
              id="info_request_comments"
              v-model="comments"
              as="textarea"
              class="form-control"
              :placeholder="$t('form.comments')"
              maxlength="2000"
              name="comments"
              :rules="noCreditCard"
            />
            <div class="error-div">
              <ErrorMessage
                as="label"
                class="error"
                name="comments"
              >
                {{ $t('messages.sensitiveData') }}
              </ErrorMessage>
              <label
                v-show="comments.length >= 2000"
                class="error"
                for="info_request_comments"
              >2000 character max</label>
            </div>
          </div>
          <div class="form-group">
            <textarea
              id="honeypot"
              class="form-control"
              name="honeypot"
              style="display:none"
            />
          </div>
          <PrivacyCheckbox
            :eu="in_eu"
            :nba="nba_info_request"
          />
          <div
            v-if="nba_info_request.isNbaForm"
            class="nba-info-request-consent"
          >
            <div class="checkbox">
              <label class="small-label">
                <Field
                  v-model="nba_info_request.nba_privacy_policy_consent"
                  class="boolean required"
                  name="nba_privacy_policy"
                  type="checkbox"
                  :rules="boxChecked"
                />
                <span class="custom-checkbox" />
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="'*' + $t('form.nba_privacy_policy_consent')" />
                <ErrorMessage
                  as="label"
                  class="error"
                  name="company_name"
                />
              </label>
            </div>
            <div class="checkbox">
              <label class="small-label">
                <input
                  v-model="nba_info_request.partner_products_services_consent"
                  class="boolean"
                  name="nba_products_and_services"
                  type="checkbox"
                >
                <span class="custom-checkbox" />
                <span v-html="$t('form.nba_products_services_consent')" />
              </label>
            </div>
            <div class="checkbox nba-info">
              <label class="small-label">
                <span v-html="$t('form.nba_info_consent')" />
              </label>
            </div>
          </div>
          <NhlInfoFormCheckboxes
            v-if="nhl_info_request.isNhlForm"
            :in_eu="in_eu"
            :nhl_info_request="nhl_info_request"
          />
          <div class="clearfix">
            <input
              v-if="resbeatWindow"
              type="submit"
              name="commit"
              :value="'Get More Info'"
              class="pull-right btn btn-secondary"
              data-ga-event-category="Info-Form"
              data-ga-event-action="Submit"
              :disabled="disabled"
              @click="submitForm"
            >
            <input
              v-else-if="quintroomsWindow"
              type="submit"
              name="commit"
              :value="'Get More Info'"
              class="btn btn-secondary"
              data-ga-event-category="Info-Form"
              data-ga-event-action="Submit"
              :disabled="disabled"
              @click="submitForm"
            >
            <input
              v-else

              id="infoFormSubmitButton"
              type="submit"
              name="commit"
              :value="$t('form.submit')"
              :class="roomcashWindow ? 'btn btn-secondary' : 'pull-right btn btn-secondary'"
              data-ga-event-category="Info-Form"
              data-ga-event-action="Submit"
              :disabled="disabled"
            >
            <span v-if="processingError">{{ $t('submitFormError') }}</span>
          </div>
        </div>
      </VForm>
    </div>
  </div>
</template>

<script>
import mixin from '../default/info_request/info_form.vue'

export default {
  mixins: [mixin],
  computed: {
    howManyOptions() {
      let options = [{ text: this.$t('form.how_many'), value: '' }]
      options.push({ text: '1-40', value: 20 })
      options.push({ text: '41+', value: 41 })
      return options
    }
  },
}
</script>
